@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* index.css */

@layer base {
  /* Light mode styles */
  body {
    @apply text-black text-[#676565] bg-white;
  }

  /* Dark mode styles */
  .dark body {
    @apply text-white text-white bg-[#010101];
  }
}

body {
  font-family: "DM Sans", sans-serif;
}

option {
  background-color: white; /* Default option background */
  color: #121212; /* Default option text */
}

.dark select option {
  background-color: #121212; /* Dark mode option background */
  color: white; /* Dark mode option text */
}

/* Dark mode styles scoped to .dark */
.dark input {
  background-color: #121212; /* Dark mode input background */
  color: #fff; /* Dark mode text color */
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
input::-moz-autofill {
  box-shadow: 0 0 0px 1000px #ffffff inset !important; /* Background color */
  -webkit-text-fill-color: #000 !important; /* Text color */
}

.dark input:-webkit-autofill,
.dark input:-webkit-autofill:focus,
.dark input:-webkit-autofill:hover,
.dark input::-moz-autofill {
  box-shadow: 0 0 0px 1000px #0D0D0D inset !important; /* Background color */
  -webkit-box-shadow: 0 0 0px 1000px #0D0D0D inset !important; /* Background color */
  -webkit-text-fill-color: #fff !important; /* Text color */
}

.dark input:-internal-autofill-selected {
  box-shadow: 0 0 0px 1000px #0D0D0D inset !important; /* Background color */
  -webkit-box-shadow: 0 0 0px 1000px #0D0D0D inset !important; /* Background color */
  -webkit-text-fill-color: #fff !important; /* Text color */
}

.dark-overlay::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.95) 100%
  );
  z-index: -1;
  left: 0;
}
input[type="radio"] {
  accent-color: #ce2600;
}
input[type="checkbox"] {
  accent-color: #ce2600;
}

[type="search"]:focus ~ .fa-search {
  display: none;
}

.PhoneInputInput:focus-visible {
  flex: 1;
  min-width: 0;
  border: none;
  outline: none;
}
.PhoneInput--focus {
  flex: 1;
  border: none;
  border-color: #fff !important;
  outline: none;
  width: 100%;
}
.PhoneInputInput {
  border: none;
  background-color: transparent;
}
input[type="tel"]:focus {
  outline: none;
}
.progress {
  transition: all;
  transform-origin: center;
  transition-duration: 1000ms;
  animation: infinite;
  transition-timing-function: linear;
  transform: scaleX(-1);
  transform: rotate(-90deg);
}

@media screen and (min-width: 300px) {
  .swiper-container {
    width: 300px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}

@media screen and (min-width: 1000px) {
  .swiper-container {
    width: 1000px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

ol {
  display: inline-block;
}
.dropdown {
  position: relative;
  color: #333;
  cursor: pointer;
}

.dropdown .arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.dropdown .arrow.open {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.dropdown .selected-value input {
  line-height: 1.5;
  font-size: 1rem;
  /* background-color: #fff; */
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
}

.dropdown .options {
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.dropdown .options.open {
  display: block;
}

.dropdown .option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.dropdown .option.selected,
.dropdown .option:hover {
  background-color: #f2f9fc;
  color: #333;
}

@media only screen and (max-width: 450px) {
  .white-overlay {
    position: relative;
    z-index: 1;
  }

  .white-overlay:before {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.2);
    background-image: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    z-index: -1;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.animated-rotate {
  animation: 1000ms rotate infinite;
  animation-timing-function: linear;
}

.dark .white-icon path {
  fill: white;
}
